.banner {
  width: 100%;
  padding: 3rem 0rem 3rem 3rem;
}
.banner_title {
  color: black;
  font-family: "montserrat";
  text-transform: uppercase;
  font-weight: 700;
  font-size: 4vw;
}

.banner__line {
  border-bottom: 2px solid #144226;
  padding-bottom: 3rem;
  position: relative;
  right: 15%;
}

.banner__line:after {
  position: absolute;
  bottom: -6px;
  left: 0;
  height: 10px;
  width: 10px;
  background: #144226;
  content: "";
  border-radius: 5px;
  right: 0;
  left: auto;
}

@media only screen and (max-width: 900px) {
  .banner_title {
    font-size: 8vw;
  }
}
