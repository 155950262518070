.footer {
  height: 200px;
  width: 100%;
  background-color: black;
  color: white;
  display: flex;
}

.footer__icons {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: 600px;
}

.footer__Vaetro {
  width: 100%;
  display: flex;
  align-items: center;
}

.footer__img {
  margin: 30px 0;
  width: auto;
  height: 80px;
  object-fit: contain;
  align-self: center;
  flex: 1;
}
.footer__icons__social {
  display: flex;
  flex: 1;
  justify-content: center;
  min-width: 250px;
  width: 100%;
  color: white;
}

.footer__menu {
  display: none;
}
.footer__right {
  display: none;
}
.MuiSvgIcon-root {
  font-size: 40px !important;
  margin: 0 20px;
  color: white;
}
.MuiSvgIcon-root:hover,
.footer__img:hocer {
  opacity: 0.8;
}

.footer__ul {
  display: flex;
  margin-top: 25px;
  flex: 1;
  justify-content: space-between;
}
.footer__li {
  list-style: none;
  text-decoration: none;
  color: white;
  margin-right: 20px;
}

.footer__li:hover {
  opacity: 0.8;
}

.footer__contactInfo {
  margin-bottom: 20px;
  align-self: center;
}
@media (min-width: 780px) {
  .footer__right {
    display: flex;
    flex-direction: column;
    flex: 0.6;
    justify-content: space-between;
  }
  .footer__menu {
    display: flex;
  }
  .footer__icons {
    flex: 0.4;
  }
  .footer__icons > img {
    width: 350px;
    align-self: start;
    margin-left: 40px;
  }
}
