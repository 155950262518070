.contact {
  display: flex;
  flex-direction: column;
}
.contact__content {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
}

.contact__contentLeft {
  flex: 0.5;
  padding-left: 20px;
}

.contact__contentRight {
  flex: 0.5;
  justify-content: flex-start;
  align-items: center;

  margin-top: 20px;
}

.contact__contentRight input,
textarea {
  margin: 10px 0;
  width: 30vw;
  font-size: large;
  font-family: inherit;
  border: 2px solid #144226;
}
.contact__contentRight input {
  height: 30px;
}

.buttonArea {
  display: flex;
  align-items: flex-end;
  width: 30vw;
}

.submit {
  margin-left: auto;
  align-self: flex-end;
  width: 120px;
  height: 50px;
  border-radius: 30px;
  background-color: #144426;
  color: white;
  font-family: inherit;
  font-weight: x-large;
  border-style: solid;
}
.submit:hover {
  cursor: pointer;
  opacity: 0.9;
}

@media screen and (max-width: 600px) {
  .contact__content {
    flex-direction: column;
    align-items: center;
  }
  .contact__contentLeft {
    flex: 1;
  }

  .contact__contentRight {
    flex: 1;
    width: 100%;
    justify-content: center;
    align-items: center;
    align-self: center;

    margin-top: 20px;
  }
  .contact__contentRight input,
  textArea {
    width: 100%;
  }
  .contact__contentRight form {
    margin: 0 10px;
  }
}
