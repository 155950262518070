.row {
  color: black;
  margin-left: 20px;
}

.row__posters {
  display: flex;
  overflow-y: hidden;
  overflow-x: scroll;
  padding: 10px;
}

.row__posters::-webkit-scrollbar {
  display: none;
}

.row > h1 {
  margin-left: 10px;
  font-weight: 800;
  color: #1a2022;
}

.square {
  background-color: #144226;
  color: #a8a8a8;
  object-fit: contain; /*keep the aspect ratio*/
  max-width: 350px;
  max-height: 400px;
  min-height: 300px;
  min-width: 300px;
  margin-right: 10px;
  transition: transform 400ms;
  flex-shrink: 0;
}

.square:hover {
  cursor: pointer;
  transform: scale(1.08);
  opacity: 0.95;
  content: attr(title);
  background: transparent;
  color: #144226;
  display: block;
}

.casitas:hover {
  /**background-image: url("./images/recommender_cdmx.JPG");*/
  background-size: contain;
  color: rgba(0, 0, 0, 0);
}

.tracker:hover {
  /**background-image: url("/covid_tracker.JPG");*/
  background-size: contain;
  color: rgba(0, 0, 0, 0);
}
