.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /*max-width: 2180px;*/
}
.home__banner {
  padding: 4em 0em; /*225vh    4rem     */
  text-align: center;
  width: 100%;
  background-color: #a8a8a865;
  text-transform: uppercase;
  -webkit-user-select: none; /* Safari */        
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard */
}

.home__banner > span {
  font-size: 3.5vw;
  font-weight: 700;
  font-stretch: extra-expanded;
  letter-spacing: 0.1rem;
  font-family: "Montserrat";
  color: #144226;
}

.datos {
  -moz-text-shadow: 2px #144226;
  -webkit-text-stroke: 2px #144226; /* width and color */
  color: transparent;
}

.home__bannerButton {
  margin: 2em 0em;
  padding: 0.9em 1.4em;
  width: 300px;
  background-color: #144226;
  text-decoration: none;
  color: white;
  border-radius: 30px;
  font-weight: 500;
  margin: auto;
  font-size: 1.5vw;
}
.home__bannerButton:hover {
  opacity: 0.9;
}
.home__bannerButton i {
  margin-right: 15px;
  font-size: 20px;
}

.home__valueProposition {
  height: 100vh;
  width: 100%;
  background-color: white;
}

@media only screen and (max-width: 800px) {
  .home__bannerButton {
    font-size: 2.3vw;
  }
}
