.demo__content {
  display: flex;
  height: 1500px;
}

.description__left {
  flex: 0.35;
  border: #144226 1px solid;
}

.demo__right {
  flex: 0.65;
  border: #144226 1px solid;
}

.hide-scrollbar {
  overflow: auto;
  -ms-overflow-style: none; /* IE 11 */
  scrollbar-width: none; /* Firefox 64 */
}
