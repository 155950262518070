* {
  margin: 0;
  font-family: "Montserrat";
}

.header__spacing {
  height: 40px;
}

body {
  scrollbar-width: none;
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media (max-width: 750px) {
  .demo__content {
    flex-direction: column;
  }
}
