.header {
  position: fixed;
  background-color: white;
  height: 45px;
  width: 100%;

  z-index: 1; /*always go to the top*/
  transition-timing-function: ease-in;
  transition: all 0.5s;
}

.header__web {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #144226;
  padding-top: 5px;
}
.header_scrolled {
  opacity: 0.45;
  background-color: white;
}

.header_scrolled:hover {
  opacity: 1;
}
.link {
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__left {
  justify-content: center;
  align-items: center;
  display: flex;
}

.header__left img {
  width: 80px;
  margin-left: 20px;

  object-fit: contain;
  cursor: pointer;
  margin-top: -4px;
}

.header__ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 600px;
  list-style: none;
}

.header__ul__li {
  list-style: none;
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}
.link {
  text-decoration: none;
}

.link:visited {
  color: #144214;
}

.header__mobile {
  display: none;
}
.header__right {
  margin-right: 30px;
  justify-content: start;
  cursor: pointer;
}
/****************************Mobile thingys*/
.logo__mobile {
  margin-top: 5px;
}

.mobilenav__icon:hover {
  cursor: pointer;
}

.bottom__menu__li {
  color: white;
  margin-top: 5px;
}

.hide {
  visibility: hidden;
  height: 0 !important;
  width: 0 !important;
}
.hide > ul,
.hide li,
.hide .link {
  display: none;
  height: 0;
}

.mobileList {
  list-style: none;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
  margin-left: 10px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
}
.mobileList li {
  margin-bottom: 3px;
}
.show {
  transition: height 0.3s linear;

  display: block;
  width: 100%;
  background-color: white;
  height: 160px;
}
.show ul {
  visibility: hidden;
}

.link:visited,
.link:active,
.link:focus,
.link {
  color: #144214;
}

@media (max-width: 780px) {
  .header__web {
    display: none;
  }
  .header__mobile {
    display: block;
    background-color: white;
  }
  .header__logo {
    margin-top: 10px;
  }
  .top {
    display: flex;
    justify-content: space-between;
    flex: 1;
  }
  .mobilenav__icon {
    align-self: center;
  }

  .mobilenav__icon:hover {
    cursor: pointer;
    margin-top: 5px;
    color: "#1234";
  }

  .MuiSvgIcon-root {
    color: #144426 !important;
  }

  .bottom {
    position: absolute;
    top: 0;
    background-color: #1a2022;
    padding-bottom: 10px;
    width: 100%;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: top;
    padding-top: 10px;
    transition: height 300ms linear;
    overflow: hidden;
  }

  .bottomClicked {
    display: none;
    transition: height 300ms linear;
  }
}

/******************************************************************/
